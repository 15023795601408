ul {
  display: flex;
}

ul li {
  list-style: none;
}

ul li a {
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 40px;
  text-align: center;
  text-decoration: none;
  color: #404040;
  margin: 0 30px;
  transition: .5s;
}

ul li a span {
  position: absolute;
  transition: transform .5s;
}

ul li a span:nth-child(1),
ul li a span:nth-child(3) {
  width: 100%;
  height: 3px;
  background: #404040;
}

ul li a span:nth-child(1) {
  top: 0;
  left: 0;
  transform-origin: right;
}

ul li a:hover span:nth-child(1) {
  transform: scaleX(0);
  transform-origin: left;
  transition: transform .5s;
}

ul li a span:nth-child(3) {
  bottom: 0;
  left: 0;
  transform-origin: left;
}

ul li a:hover span:nth-child(3) {
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .5s;
}

ul li a span:nth-child(2),
ul li a span:nth-child(4) {
  width: 3px;
  height: 100%;
  background: #404040;
}

ul li a span:nth-child(2) {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: bottom;
}

ul li a:hover span:nth-child(2) {
  transform: scale(1);
  transform-origin: top;
  transition: transform .5s;
}

ul li a span:nth-child(4) {
  top: 0;
  right: 0;
  transform: scale(0);
  transform-origin: top;
}

ul li a:hover span:nth-child(4) {
  transform: scale(1);
  transform-origin: bottom;
  transition: transform .5s;
}

.linkedin:hover {
  color: #3b5998;
}

.linkedin:hover span {
  background: #3b5998;
}

.github:hover {
  color: #1da1f2;
}

.github:hover span {
  background: #1da1f2;
}

.envelope:hover {
  color: #c32aa3;
}

.envelope:hover span {
  background: #c32aa3;
}

ul li a .twitter {
  color: #1da1f2;
}

ul li a:hover:nth-child(3) {
  color: #c32aa3;
}

ul li a:hover:nth-child(4) {
  color: #dd4b39;
}