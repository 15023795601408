.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.contact h2:nth-child(1) {
    font-weight: 700;
    font-size: 80px;
    line-height: 108px;
    color: #ec7b23;
    margin-top: 20px;
}

footer {
    background: #f1f1f3;
}

.footerPortraitAndName {
    display: flex;
    width: 100%;
    margin-top: 70px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
    margin-right: 11%;
}

.footerPortrait {
    width: 25%;
    display: flex;
    justify-content: flex-end;
}

.footerPortraitImg {
    justify-content: center;
    display: flex;
    border-radius: 50%;
    flex-grow: 0;
    width: 50%;
}

.footerName {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footerName h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    margin: 0;
}

.footerName h2 {
    font-weight: 900;
    font-size: 32px;
    line-height: 43px;
    color: #ec7b23;
    margin: 0;
}

.phoneNumber h6 {
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    margin: 0;
}

.email h6 {
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    margin: 0;
}

.cvLogo {
    margin-top: 25px;
    height: 50px;
    width: 50px;
}

@media (max-width: 900px) {
    .timeToTalk {
        font-size: 70px !important;
    }

    .footerPortraitAndName {
        margin: auto;
        scale: 80%;
    }

    .portraitImg {
        width: 125px;
        height: 125px;
    }

    .footerName h2 {
        font-weight: 700;
        font-size: 28px;
    }

    .footerName h3 {
        font-weight: 800;
        font-size: 28px;
    }

    .socialMediaLinks {
        margin: 0px;
        align-items: center;
        justify-content: center;
    }

    .phoneNumber h6 {
        font-size: 28px;
    }

    .email h6 {

        font-size: 28px;
    }
}