.techBox {
    display: flex;
    width: 100%;
    height: 300px;
    justify-content: space-evenly;
    background: #9cb2a5;
}

.techText {
    font-weight: 700;
    font-size: 80px;
    line-height: 108px;
    display: flex;
    align-items: center;
    color: #733236;
}

.techInfo {
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    display: flex;
    align-items: center;
    width: 49%;
}

.js {
    background: #fbb03b;
}

.react {
    background: #ec7b23;
}

.mob {
    background: #ccecf8;
}

@media (max-width: 900px) {
    .techInfo {
        width: 100%;
    }

    .techText h2 {
        margin: 0;
    }

    .techBox {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .techInfo {
        width: 90%;
        font-size: 21px;
    }
}